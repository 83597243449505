import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Pagination, Input, Spin, Modal, Typography } from "antd";
import {
  fetchSkippedRecords,
  setPage,
  setPageSize,
  setSearchTerm,
} from "../../feature/slice/SkippedRecordsSlice";

const SkippedRecord = () => {
  const dispatch = useDispatch();
  const { records, page, pageSize, totalRecords, searchTerm, loading } =
    useSelector((store) => store.skippedRecordDetails);

  const [searchInput, setSearchInput] = useState(searchTerm);

  useEffect(() => {
    dispatch(
      fetchSkippedRecords({ page, limit: pageSize, search: searchTerm })
    );
  }, [dispatch, page, pageSize, searchTerm]);

  const handlePaginationChange = (page, pageSize) => {
    dispatch(setPage(page));
    dispatch(setPageSize(pageSize));
  };

  const handleSearch = () => {
    dispatch(setSearchTerm(searchInput));
  };

  const columns1 = [
    {
      title: "Table",
      dataIndex: "table",
      key: "table",
      width: "15%", 
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      render: (record) => {
        const previewText = JSON.stringify(record)?.slice(1, 71); 

        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
            onClick={() => showMessageModal(record)}
          >
            <Typography.Link>{previewText}...</Typography.Link>{" "}
          </div>
        );
      },
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      width: "20%", 
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      width: "20%", 
    },
  ];

  // Function to show the modal with formatted JSON
  const showMessageModal = (message) => {
    const parsedMessage = message;
    const renderJsonData = (data) => {
      return Object.entries(data).map(([key, value]) => {
        return (
          <div key={key} style={{ marginBottom: "5px" }}>
            <strong>{key}:</strong>
            {typeof value === "object" && value !== null ? (
              <div style={{ marginLeft: "20px" }}>{renderJsonData(value)}</div>
            ) : (
              <span>{JSON.stringify(value)}</span>
            )}
          </div>
        );
      });
    };

    Modal.info({
      title: "Message Data",
      content: (
        <div style={{ maxHeight: "600px", overflowY: "auto" }}>
          {renderJsonData(parsedMessage)}
        </div>
      ),
      onOk() {},
      closable: true,
      centered: true,
    });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between", 
          marginBottom: 16,
          padding: "10px",
        }}
      >
        <h2 style={{ fontSize: "18px", fontWeight: "bold", margin: 0 }}>
          {" "}
          Skipped Records
        </h2>
        <Input.Search
          placeholder="Search by Table or Reason or Remarks"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          onSearch={handleSearch}
          style={{ marginLeft: "16px", width: "100%", maxWidth: "400px" }} 
        />
      </div>
      {loading ? (
        <Spin spinning={loading} size="large" fullscreen />
      ) : (
        <div
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
            overflow: "hidden", 
          }}
        >
          <div style={{ overflowX: "auto" }}>
            <Table
              columns={columns1}
              dataSource={records}
              pagination={false}
              rowKey="_id"
            />
          </div>
          {records?.length > 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <div style={{ margin: "10px 0" }}>
                Showing {(page - 1) * pageSize + 1} -{" "}
                {Math.min(page * pageSize, totalRecords)} / {totalRecords}{" "}
                Results
              </div>
              <Pagination
                current={page}
                pageSize={pageSize}
                showSizeChanger
                onShowSizeChange={handlePaginationChange}
                total={totalRecords}
                onChange={handlePaginationChange}
                style={{ marginTop: 16, marginBottom: 16 }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SkippedRecord;
