import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../../components/header/Header';
import SideNav from '../../components/sideNav/SideNav';

const Layout = () => {
    const [collapsed, setCollapsed] = useState(false);

    // Adjust width based on collapsed state
    const sideNavWidth = collapsed ? 80 : 200;

    return (
        <div style={{ backgroundColor: "rgba(203, 203, 203, 0.3)", height: "100vh" }}>
            {/* Header */}
            <div className="w-full h-16 flex align-center items-center p-4 bg-white">
                <Header />
            </div>

            {/* Main layout with SideNav and content */}
            <div className="dashboard-container" style={{ display: "flex", height: "calc(100vh - 64px)" }}>
                {/* SideNav */}
                <SideNav
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                />

                {/* Content area (Outlet) */}
                <div
                    style={{
                        flex: 1, // Make this div take up the remaining space
                        overflow: "auto", 
                        marginLeft: sideNavWidth, // Adjust content margin based on the sidenav width
                        padding: "16px", // Optional padding for content area
                    }}
                >
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default Layout;
