import { client } from "../../utils/apiClient";
import { asyncHandler } from "../../utils/asyncHandler";

const getSkippedRecordsData = async ({ page, limit, search }) => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-skipped-records`, {
      params: { page, limit, search },
    })
  );
  if (err) return err;
  return data;
};

export { getSkippedRecordsData };
