import {
    buildCreateSlice,
    asyncThunkCreator,
  } from "@reduxjs/toolkit";
  import {
    getAllDistinctStateMaster as _getAllDistinctStateMaster,
    getAllDistinctCityByState as _getAllDistinctCityByState,
    getAllDistinctMicroMarketByCity as _getAllDistinctMicroMarketByCity,
    getAllDistinctLocalityByCityAndMicromarket as _getAllDistinctLocalityByCityAndMicromarket,
  } from "../services/MasterAddressAPI";
  import { get } from "lodash";
  
  const createSliceWithThunks = buildCreateSlice({
    creators: {
      asyncThunk: asyncThunkCreator,
    },
  });
  
  const initialState = {
    loading: false,
    stateMaster: [],
    cityMaster: [],
    microMarketMaster: [],
    localityMaster: [],
  };

  
  const masteraddressSlice = createSliceWithThunks({
    name: "masterAddressDetails",
    initialState,
    reducers: (create) => ({
      getAllDistinctStateMaster: create.asyncThunk(
        async (_, thunkAPI) => {
          try {
            let data = await _getAllDistinctStateMaster();
            return data;
          } catch (err) {
            const message = get(err, "message", "Something Went Wrong!");
            const name = get(err, "name", "Error!");
            const statusCode = get(err, "metadata.statusCode", "");
            return thunkAPI.rejectWithValue({ message, name, statusCode });
          }
        },
        {
          pending: (state) => {
            state.stateMaster = [];
          },
          rejected: (state) => {
            state.stateMaster = [];
          },
          fulfilled: (state, action) => {
            const stateMasterArray = action?.payload?.data?.map((item) => ({
              label: item.state,
              key: item._id,
              value: item._value,
            }));
            state.stateMaster = stateMasterArray;
          },
        }
      ),
      getAllDistinctCityByState: create.asyncThunk(
        async (_, thunkAPI) => {
          try {
            let data = await _getAllDistinctCityByState(_);
            return data;
          } catch (err) {
            const message = get(err, "message", "Something Went Wrong!");
            const name = get(err, "name", "Error!");
            const statusCode = get(err, "metadata.statusCode", "");
            return thunkAPI.rejectWithValue({ message, name, statusCode });
          }
        },
        {
          pending: (state) => {
            state.cityMaster = [];
          },
          rejected: (state, action) => {
            state.cityMaster = [];
          },
          fulfilled: (state, action) => {
            const cityMasterArray = [];
            const cities = action.payload.data || [];
            if (Array.isArray(cities) && cities.length > 0) {
              for (const city of cities) {
                const element = {
                  label: city.city,
                  key: city._id,
                  value: city._id,
                };
                cityMasterArray.push(element);
              }
            }
            state.cityMaster = cityMasterArray;
          },
        }
      ),
      getAllDistinctMicroMarketByCity: create.asyncThunk(
        async (_, thunkAPI) => {
          try {
            const city =
              thunkAPI.getState().masterAddressDetails?.masterAddressDetails?.city;
            let data = await _getAllDistinctMicroMarketByCity(_);
            return data;
          } catch (err) {
            const message = get(err, "message", "Something Went Wrong!");
            const name = get(err, "name", "Error!");
            const statusCode = get(err, "metadata.statusCode", "");
            return thunkAPI.rejectWithValue({ message, name, statusCode });
          }
        },
        {
          pending: (state) => {
            state.microMarketMaster = [];
          },
          rejected: (state, action) => {
            state.microMarketMaster = [];
          },
          fulfilled: (state, action) => {
            const microMarketMasterArray = [];
            const data = action?.payload?.data || [];
            if (data.length > 0) {
              for (let index = 0; index < data.length; index++) {
                const element = {
                  label: data[index]?.microMarket,
                  key: data[index]?._id,
                  value: data[index]?._id,
                };
                microMarketMasterArray.push(element);
              }
            }
            state.microMarketMaster =
              data.length === 1 && data[0] === "" ? [] : microMarketMasterArray;
          },
        }
      ),
      getAllDistinctLocalityByCityAndMicromarket: create.asyncThunk(
        async ({ city }, thunkAPI) => {
          try {
            const microMarket = thunkAPI.getState().masterAddressDetails?.masterAddressDetails?.microMarket;
            let data = await _getAllDistinctLocalityByCityAndMicromarket(city, microMarket);
            return data;
          } catch (err) {
            const message = get(err, "message", "Something Went Wrong!");
            const name = get(err, "name", "Error!");
            const statusCode = get(err, "metadata.statusCode", "");
            return thunkAPI.rejectWithValue({ message, name, statusCode });
          }
        },
        {
          pending: (state) => {
            state.localityMaster = [];
          },
          rejected: (state, action) => {
            state.localityMaster = [];
          },
          fulfilled: (state, action) => {
            const localityMasterArray = action?.payload?.data?.map((locality) => ({
                label: locality?.locality,
                key: locality?._id,
                value: locality?._id,
                pincode: locality?.pincodeId?.pincode,
                pincodeId: locality?.pincodeId?._id
              }));
            state.localityMaster = localityMasterArray;
          },
        }
      ),
      
    }),
  });
  
  export const {
    setCurrentPage,
    setPageSize,
    getAllDistinctStateMaster,
    getAllDistinctCityByState,
    getAllDistinctMicroMarketByCity,
    getAllDistinctLocalityByCityAndMicromarket,
  } = masteraddressSlice.actions;
  export default masteraddressSlice.reducer;
  