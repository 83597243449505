import { client } from "../../utils/apiClient";
import { asyncHandler } from "../../utils/asyncHandler";

const getLocalities = async () => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-all-distinct-locality-master`)
  );
  if (err) return err;
  return data;
};

const getCities = async () => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-all-distinct-city-master`)
  );
  if (err) return err;
  return data;
};

export { getLocalities, getCities };
