import React, { useEffect, useState } from "react";
import { Card, Row, Col, Select, Form, Spin, Button } from "antd";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts"; // ResponsiveContainer added
import {
    getDashboardCounts,
    getUserDetails
} from "../../feature/services/DashboardDetailsAPI";
import { useDispatch, useSelector } from "react-redux";
import {
    getAllDistinctStateMaster,
    getAllDistinctCityByState,
    getAllDistinctLocalityByCityAndMicromarket
} from "../../feature/slice/MasterAddressSlice";
import { setDashboardFilter } from "../../feature/slice/DashboardDetailsSlice";
import "./Dashboard.css";

const { Option } = Select;

const Dashboard = () => {
    const [state, setState] = useState(null);
    const [city, setCity] = useState(null);
    const [locality, setLocality] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [topCount, setTopCount] = useState(5);
    const [barChartData, setBarChartData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingUsr, setLoadingUsr] = useState(true);

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const { stateMaster, cityMaster, localityMaster } = useSelector(
        (store) => store.masterAddressDetails
    );

    const [cardData, setCardData] = useState([
        { title: "Property", count: "" },
        { title: "Transaction", count: "" },
        { title: "Company", count: "" },
        { title: "Connect", count: "" }
    ]);

    useEffect(() => {
        dispatch(getAllDistinctStateMaster());
    }, [dispatch]);

    useEffect(() => {
        if (state) {
            dispatch(getAllDistinctCityByState(state));
        }
    }, [state, dispatch]);

    useEffect(() => {
        if (city) {
            dispatch(getAllDistinctLocalityByCityAndMicromarket({ city }));
        }
    }, [city, dispatch]);

    const fetchDashboardData = async () => {
        try {
            setLoading(true);
            const dashboardData = await getDashboardCounts({ state, city, locality });
            if (Array.isArray(dashboardData)) {
                // Set the data for the bar chart without modifying it
                setBarChartData(dashboardData);
                console.log("Bar Chart Data:", dashboardData);

                // Extract residential and commercial data
                const propertyResidential = dashboardData.find(item => item.title === "PRC")?.count || 0;
                const propertyCommercial = dashboardData.find(item => item.title === "PCC")?.count || 0;
                const transactionResidential = dashboardData.find(item => item.title === "TRC")?.count || 0;
                const transactionCommercial = dashboardData.find(item => item.title === "TCC")?.count || 0;

                // Update cardData with residential and commercial counts
                setCardData([
                    {
                        title: "Property",
                        count: dashboardData.find(item => item.title === "Property")?.count || 0,
                        residential: propertyResidential,
                        commercial: propertyCommercial,
                    },
                    {
                        title: "Transaction",
                        count: dashboardData.find(item => item.title === "Transaction")?.count || 0,
                        residential: transactionResidential,
                        commercial: transactionCommercial,
                    },
                    {
                        title: "Company",
                        count: dashboardData.find(item => item.title === "Company")?.count || 0,
                    },
                    {
                        title: "Connect",
                        count: dashboardData.find(item => item.title === "Connect")?.count || 0,
                    }
                ]);
            }
        } catch (error) {
            console.error("Failed to fetch dashboard data:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchUserData = async (topCount) => {
        try {
            setLoadingUsr(true);
            const userDetails = await getUserDetails(topCount); // Pass topCount to API call
            if (Array.isArray(userDetails)) {
                setUserData(userDetails); // Set fetched data directly
            }
        } catch (error) {
            console.error("Failed to fetch user data:", error);
        } finally {
            setLoadingUsr(false);
        }
    };

    useEffect(() => {
        fetchDashboardData();
    }, [state, city, locality]);

    const handleTopCountChange = (value) => {
        setTopCount(value); // Update topCount state
        fetchUserData(value); // Fetch new data based on the selected topCount
    };

    useEffect(() => {
        fetchUserData(topCount);
    }, [topCount]);

    const handleStateChange = (value) => {
        form.setFieldsValue({ city: null, locality: null });
        setCity(null);
        setLocality(null);
        setState(value);
        dispatch(getAllDistinctCityByState(value));
        dispatch(setDashboardFilter({ name: "state", value }));
    };

    const handleCityChange = (value) => {
        form.setFieldsValue({ locality: null });
        setCity(value);
        setLocality(null);
        dispatch(getAllDistinctLocalityByCityAndMicromarket({ city: value }));
        dispatch(setDashboardFilter({ name: "city", value }));
    };

    const handleLocalityChange = (value) => {
        setLocality(value);
        dispatch(setDashboardFilter({ name: "locality", value }));
    };

    const renderStateOptions = () =>
        stateMaster?.map((state) => (
            <Select.Option key={state.key} value={state.value}>
                {state.label}
            </Select.Option>
        ));

    const renderCityOptions = () =>
        cityMaster?.map((city) => (
            <Select.Option key={city.key} value={city.value}>
                {city.label}
            </Select.Option>
        ));

    const renderLocalityOptions = () =>
        localityMaster?.map((locality) => (
            <Select.Option key={locality.key} value={locality.value}>
                {locality.label}
            </Select.Option>
        ));

    const renderBarChart = (data) => (
        <ResponsiveContainer width="100%" height={400}>
            {barChartData.length > 0 ? (
                <BarChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="title" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="count" fill="#fb923c" barSize={50} />
                </BarChart>
            ) : (
                <p>Loading chart...</p>
            )}
        </ResponsiveContainer>
    );

    const filterData = () => {
        let data = barChartData;  
        // Filter by required categories
        const requiredCategories = ["Property", "Transaction", "Company", "Connect"];
        data = data.filter((item) => requiredCategories.includes(item.title));
        setFilteredData(data);
    };

    useEffect(() => {
        filterData();  
    }, [barChartData, state, city, locality]);

    const renderUserBarChart = (data) => (
        <ResponsiveContainer width="100%" height={400}>
            <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="userName"
                    interval={0}
                    angle={-20}
                    textAnchor="end"
                    tick={{ fontSize: 12 }}
                />
                <YAxis
                    tick={{ fontSize: 12 }}
                    tickFormatter={(value) => new Intl.NumberFormat().format(value)}
                />
                <Tooltip />
                <Legend />
                <Bar dataKey="totalCount" fill="#fb923c" barSize={40} />
            </BarChart>
        </ResponsiveContainer>
    );


    const handleClear = () => {
        form.resetFields();
        setState(null);
        setCity(null);
        setLocality(null);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <div className="m-4">
                <div className="pl-3">
                    <h1 style={{ fontSize: "18px", fontWeight: "bold" }}>Dashboard</h1>
                </div>
                <Row gutter={16} style={{ margin: "30px 0" }}>
                    {cardData.map((item) => (
                        <Col key={item.title} xs={24} sm={12} md={8} lg={6}>
                            <Card
                                title={item.title}
                                bordered={false}
                                style={{
                                    marginTop: "10px",
                                    textAlign: "center",
                                    boxShadow: "4px 4px 4px 4px rgba(0, 0, 0, 0.25)",
                                    height: "200px",
                                }}
                            >
                                <h2>{item.count ? item.count : 0}</h2>
                                {(item.title === "Property" || item.title === "Transaction") && (
                                    <div style={{ display: "flex", justifyContent: "space-around", marginTop: "20px" }}>
                                        <p style={{ display: "flex", flexDirection: "column" }}><span>Residential</span>
                                            <span>{item.residential}</span>
                                        </p>
                                        <p style={{ display: "flex", flexDirection: "column" }}><span>Commercial</span>
                                            <span>{item.commercial}</span>
                                        </p>
                                    </div>
                                )}
                            </Card>
                        </Col>
                    ))}
                </Row>
                <div
                    className="mb-4 p-4"
                    style={{
                        backgroundColor: "white",
                        boxShadow: "4px 4px 4px 4px rgba(0, 0, 0, 0.25)",
                        borderRadius: "10px",
                    }}
                >
                    <Row justify="space-between" align="middle" style={{ marginBottom: "20px" }}>
                        <h1 style={{ margin: 0 }}>All Data</h1>
                        <Button
                            type="primary"
                            style={{ backgroundColor: "#fb923c", color: "#FFFFFF" }}
                            onClick={handleClear}
                        >
                            Clear
                        </Button>
                    </Row>
                    <Row gutter={16} style={{ marginBottom: "20px" }}>
                        <Form
                            form={form}
                            layout="vertical"
                            className="flex flex-col sm:flex-row w-full justify-between gap-4 p-4"
                        >
                            <Form.Item name="state" label="State" className="flex-1">
                                <Select
                                    placeholder="Select State"
                                    onChange={handleStateChange}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {renderStateOptions()}
                                </Select>
                            </Form.Item>
                            <Form.Item name="city" label="City" className="flex-1">
                                <Select
                                    placeholder="Select City"
                                    onChange={handleCityChange}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {renderCityOptions()}
                                </Select>
                            </Form.Item>
                            <Form.Item name="locality" label="Locality" className="flex-1">
                                <Select
                                    placeholder="Select Locality"
                                    onChange={handleLocalityChange}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {renderLocalityOptions()}
                                </Select>
                            </Form.Item>
                        </Form>
                    </Row>
                    <Row>
                        <Col span={24}>
                            {loading ? (
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "300px" }}>
                                    <Spin size="large" />
                                </div>
                            ) : (
                                renderBarChart(filteredData)
                            )}
                        </Col>
                    </Row>
                </div>
                <div className="card-container mt-4 p-4"
                    style={{
                        backgroundColor: "white",
                        boxShadow: "4px 4px 4px 4px rgba(0, 0, 0, 0.25)",
                        borderRadius: "10px",
                    }}
                >
                    <Row justify="space-between" align="middle">
                        <h1 style={{ margin: 0 }}>Top {topCount} Users</h1>
                        <Select value={topCount} onChange={handleTopCountChange}>
                            <Option value={5}>Top 5</Option>
                            <Option value={10}>Top 10</Option>
                            <Option value={20}>Top 20</Option>
                        </Select>
                    </Row>
                    <Row>
                        <Col span={24}>
                            {loadingUsr ? (
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "300px" }}>
                                    <Spin size="large" />
                                </div>
                            ) : (
                                renderUserBarChart(userData)
                            )}
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
