import { buildCreateSlice, asyncThunkCreator } from "@reduxjs/toolkit";
import { getSkippedRecordsData as _getSkippedRecordsData } from "../services/SkippedRecordAPI";
import { get } from "lodash";

const createSliceWithThunks = buildCreateSlice({
  creators: {
    asyncThunk: asyncThunkCreator,
  },
});

const initialState = {
  loading: false,
  records: [],
  page: 1,
  pageSize: 10,
  totalRecords: 0,
  searchTerm: '',
};

const skippedRecordsSlice = createSliceWithThunks({
  name: "skippedRecords",
  initialState,
  reducers: (create) => ({
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    fetchSkippedRecords: create.asyncThunk(
      async (params, thunkAPI) => {
        try {
          const data = await _getSkippedRecordsData(params);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something went wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
          state.records = [];
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {          
          state.records = action.payload?.data?.data || [];
          state.totalRecords = action.payload?.data?.totalRecords || 0;
          state.loading = false;
        },
      }
    ),
    resetSkippedRecords: (state) => {
      state.records = [];
      state.page = 1;
      state.pageSize = 10;
      state.searchTerm = '';
      state.totalRecords = 0;
    },
  }),
});

export const {
  setPage,
  setPageSize,
  setSearchTerm,
  fetchSkippedRecords,
  resetSkippedRecords,
} = skippedRecordsSlice.actions;

export default skippedRecordsSlice.reducer;
