import React, { useEffect, useState } from "react";
import { Table, Typography, Pagination, Button } from "antd";
import { useNavigate, useParams } from "react-router";
// import "./Directors.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentPage,
  setPageSize,
  getDirectorsList,
  setDirectorInitialState,
  setDirectorId,
} from "../../feature/slice/DirectorDetailsSlice";
import Header from "../../components/header/Header";
import SideNav from "../../components/sideNav/SideNav";
import { setSelectedNav } from "../../feature/slice/FiltersSlice";
import AddProperty from "../../assets/add.svg";

const Directors = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading, directorDetailsData, currentPage, pageSize } = useSelector(
    (store) => store.directorDetails
  );

  const { directorsCnt } = useSelector((store) => store.companyDetails);
  const handleRowClick = (record) => {
    navigateTo(`/directorDetails/${record.directorId}`);
  };

  const columns = [
    {
      title: "Director Name",
      dataIndex: "directorName",
      width: 250,
      render: (text, record) => (
        <div style={{ cursor: "pointer" }} onClick={() => handleRowClick(record)}>
          {text}
        </div>
      ),
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      width: 200,
      render: (text, record) => (
        <div style={{ cursor: "pointer" }} onClick={() => handleRowClick(record)}>
          {text}
        </div>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "emailAddress",
      width: 250,
      render: (text, record) => (
        <div style={{ cursor: "pointer" }} onClick={() => handleRowClick(record)}>
          {text}
        </div>
      ),
    },
    {
      title: "Contact Number",
      dataIndex: "contactNumber",
      width: 250,
      render: (text, record) => (
        <div style={{ cursor: "pointer" }} onClick={() => handleRowClick(record)}>
          {text}
        </div>
      ),
    },
    {
      title: "Completion Ratio",
      dataIndex: "completionRatio",
      width: 250,
      render: (text, record) => (
        <div style={{ cursor: "pointer" }} onClick={() => handleRowClick(record)}>
          {text}
        </div>
      ),
    },
  ];


  useEffect(() => {
    const body = {
      pageSize: pageSize,
      page: currentPage,
      companyId: id,
    };
    dispatch(getDirectorsList(body));
  }, []);

  const handlePaginationChange = (page) => {
    dispatch(setCurrentPage(page));
    const body = {
      pageSize: pageSize,
      page: page,
      companyId: id,
    };
    dispatch(getDirectorsList(body));
  };

  const onShowSizeChange = (current, pageSize) => {
    dispatch(setPageSize(pageSize));
    dispatch(setCurrentPage(current));
    const body = {
      pageSize: pageSize,
      page: current,
      companyId: id,
    };
    dispatch(getDirectorsList(body));
  };

  return (
    <React.Fragment>
      <div>
        <div style={{ display: "flex", flexDirection: "column", width: "100%",}}>
          <div
            style={{
              display: "inline-flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: "10px",
              marginBottom: "20px",
            }}
          >
            <h1 style={{ fontSize: "18px", fontWeight: "bold", marginLeft: "20px" }}>Directors</h1>
            <div className="list-options m-4 flex">
              <Button
                className="mr-4"
                style={{ backgroundColor: "#fb923c", color: "#FFFFFF" }}
                onClick={() => {
                  navigateTo("/directorDetails");
                  dispatch(setDirectorId(null));
                  dispatch(setDirectorInitialState());
                }}
              >
                <img src={AddProperty} alt="add" />
                Add Director
              </Button>
              <Button
                onClick={() => {
                  navigateTo("/company");
                }}
              >
                Back
              </Button>
            </div>
          </div>
          <div className="m-4" style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', backgroundColor: '#FFFFFF', borderRadius: '8px' }}>
            <Table
              className="m-4 no-column-lines"
              bordered
              dataSource={directorDetailsData}
              columns={columns}
              size="small"
              loading={loading}
              rowKey="directorId"
              pagination={false}
              style={{ width: "auto" }}
            />
            <br />
            {directorDetailsData?.length > 0 && (
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                onChange={(currPage, pageSize) =>
                  handlePaginationChange(currPage, pageSize)
                }
                total={directorsCnt}
                style={{marginBottom: '10px'}}
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Directors;
