import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/auth/Login";
import { ProtectedRoute } from "./service/ProtectedRoute";
import AuthProvider from "./service/authProvider";
import PropertyDetailsCard from "./pages/propertyDetailsCard/PropertyDetailsCard";
import CompanyDetailsCard from "./pages/companyDetailsCard/CompanyDetailsCard";
import NotFound from "./NotFound";
import TransactionDetailsCard from "./pages/transactionDetailsCard/TransactionDetailsCard";
import ConnectDetailsCard from "./pages/connectDetailsCard/ConnectDetailsCard.jsx";
import DirectorDetailsCard from "./pages/directordetailscard/DirectorDetailsCard";
import Directors from "./pages/directors/Directors";
import Transactions from "./pages/transactions/Transactions";
import setUpInterceptor from "./utils/apiClient/interceptor";
import { useSelector } from "react-redux";
import TransactionListDetails from "./pages/transactionListDetails/TransactionListDetails.jsx";
import Property from "./pages/property/Property.jsx";
import ReportDetailsCard from "./pages/reportDetailsCard/ReportDetailsCard.jsx";
import Company from "./pages/company/Company.jsx";
import Connect from "./pages/connect/Connect.jsx";
import Report from "./pages/reports/Report.jsx";
import Layout from "./pages/layout/Layout.jsx";
import Dashboard from "./pages/dashboard/Dashboard.jsx";
import SkippedRecord from "./pages/skippedRecords/SkippedRecord.jsx";

const App = () => {
  const { accessToken } = useSelector((store) => store.user);
  setUpInterceptor(accessToken);

  return (
    <AuthProvider>
  <Routes>
    <Route path="/" element={<Login />} />
    {/* Protected routes */}
    <Route element={<ProtectedRoute />}>
      {/* Layout containing Header and SideNav */}
      <Route element={<Layout />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/property" element={<Property />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/company" element={<Company />} />
        <Route path="/connect" element={<Connect />} />
        <Route path="/report" element={<Report />} />
        <Route path="/skipped-records" element={<SkippedRecord />} />
        <Route path="/propertyDetails/:id?" element={<PropertyDetailsCard />} />
        <Route path="/connectDetails/:id?" element={<ConnectDetailsCard />} />
        <Route path="/transactionDetails/:id?" element={<TransactionDetailsCard />} />
        <Route path="/directorDetails/:id?" element={<DirectorDetailsCard />} />
        <Route path="/transactionsList/:id" element={<Transactions />} />
        <Route path="/transactionListDetails/:id" element={<TransactionListDetails />} />
        <Route path="/directorsList/:id" element={<Directors />} />
        <Route path="/companyDetails/:id?" element={<CompanyDetailsCard />} />
        <Route path="/report-details/:userId/:userName/:category" element={<ReportDetailsCard />} />
      </Route>
    </Route>

    {/* Fallback route */}
    <Route path="*" element={<NotFound />} />
  </Routes>
</AuthProvider>
  );
};

export default App;
