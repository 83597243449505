import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Input,
  Form,
  DatePicker,
  Select,
  InputNumber,
  Flex,
  Divider,
  Space,
  Button,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
const { TextArea } = Input;

const componentMapping = {
  input: Input,
  inputNumber: InputNumber,
  textArea: TextArea,
  dropdown: Select,
  datepicker: DatePicker,
  tag: Input,
};

function FormElement({
  type,
  label,
  required,
  name,
  width = "100%",
  marginTop = "7px",
  ...props
}) {
  const inputRef = useRef(null);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && type !== "tag") {
      e.preventDefault(); // Prevent form submission
      e.stopPropagation(); // Stop event bubbling
    }
  };

  if (!type)
    return <Form.Item style={{ visibility: "hidden", marginTop }}></Form.Item>;

  // dynamically select a component from componentMapping object
  const Component = componentMapping[type];
  if (type === "tag") {
    return (
      <>
        <Flex vertical>
          <Form.Item
            ref={inputRef}
            type="text"
            labelCol={{ span: 24 }}
            label={label}
            initialValue={props?.value}
          >
            <Component
              style={{
                width: width, // Responsive width
                maxWidth: "100%", // Ensure it does not exceed the container width
                marginTop: marginTop,
              }}
              {...props}
              onKeyDown={handleKeyPress}
            />
          </Form.Item>
        </Flex>
      </>
    );
  }
  if (type === "inputNumber") {
    return (
      <Form.Item
        name={name}
        min={0}
        label={label}
        labelCol={{ span: 24 }}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        rules={
          props.rules || [
            {
              required,
              message: `Please input ${label}!`,
            },
          ]
        }
        required={required}
        initialValue={props?.value}
        validateTrigger={["onChange", "onBlur"]}
      >
        <Component style={{ width }} {...props} onKeyPress={handleKeyPress} />
      </Form.Item>
    );
  }
  if (type === "datepicker") {
    return (
      <Form.Item
        name={name}
        label={label}
        labelCol={{ span: 24 }}
        rules={
          props.rules || [
            {
              required,
              message: `Please input ${label}!`,
            },
          ]
        }
        required={required}
        validateTrigger={["onChange", "onBlur"]}
        defaultValue={props?.value}
        initialValue={props?.value}
      >
        <Component style={{ width }} {...props} format={"DD-MM-YYYY"} onKeyPress={handleKeyPress} />
      </Form.Item>
    );
  }
  if (type === "dropdown") {
    return (
      <Form.Item
        name={name}
        label={label}
        labelCol={{ span: 24 }}
        rules={
          props.rules || [
            {
              required,
              message: `Please input ${label}`,
            },
          ]
        }
        required={required}
        validateTrigger={["onChange", "onBlur"]}
        initialValue={props?.value}
      >
        <Component
          style={{ width }}
          showSearch // Enable search in dropdown
          onSearch={debounce((val) => {
            if (props.onSearch) {
              props.onSearch(val); // Trigger search function passed from props
            }
          }, 300)} // Debounce the search function to limit calls
          filterOption={(input, option) => {
            // Filter the dropdown options based on the search input
            return option.children.toLowerCase().includes(input.toLowerCase());
          }}
          onKeyPress={handleKeyPress}
          dropdownRender={(menu) => (
            <>
              {menu}
                <Button
                  type="text"
                  onClick={props?.createRecord}
                >
                  {props?.createOptionLabel}
                </Button>
            </>
          )}
          {...props}
        />
      </Form.Item>
    );
  }
  if (props?.showSearch) {
    return (
      <Form.Item
        name={name}
        label={label}
        labelCol={{ span: 24 }}
        rules={
          props.rules || [
            {
              required,
              message: `Please input ${label}!`,
            },
          ]
        }
        required={required}
        validateTrigger={["onChange", "onBlur"]}
        initialValue={props?.value}
      >
        <Component
          style={{ width }}
          onSearch={(val) => {
            if (!val) return debounce(props?.onSearch(val), 300);
          }}
          onKeyPress={handleKeyPress}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: "8px 0" }} />
              <Space style={{ padding: "0 8px 4px", float: "right" }}>
                <Button
                  type="text"
                  icon={<PlusOutlined />}
                  onClick={props?.createRecord}
                >
                  {props?.createOptionLabel}
                </Button>
              </Space>
            </>
          )}
          {...props}
        />
      </Form.Item>
    );
  }
  return (
    <Form.Item
      name={name}
      label={label}
      labelCol={{ span: 24 }}
      rules={
        props.rules || [
          {
            required,
            message: `Please input ${label}!`,
          },
        ]
      }
      required={required}
      validateTrigger={["onChange", "onBlur"]}
      initialValue={props?.value}
    >
      <Component style={{ width }} {...props} onKeyPress={handleKeyPress} />
    </Form.Item>
  );
}

export default FormElement;
