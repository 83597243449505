import React, { Fragment, useEffect, useState } from "react";
import {
  Form,
  Col,
  Row,
  Divider,
  Select,
  Button,
  Spin,
  Upload,
  message,
  Checkbox,
  Image,
} from "antd";
import { UploadOutlined, CloseOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid"; // Import v4 function as uuidv4
import Title from "antd/es/typography/Title";
import FormElement from "../../components/formElement/FormElement";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { FaArrowLeft } from 'react-icons/fa';
import {
  getPropertyDetailsData,
  setBuildingInfoEditable,
  setBuildingsInfo,
  setPropertyId,
  updateBuildingsInfo,
  setPropertyInitialState,
  saveBuildingsInfo,
  setLoading,
} from "../../feature/slice/PropertyDetailsSlice";
import {
  getAllDistinctStateMaster,
  getAllDistinctCityByState,
  getAllDistinctMicroMarketByCity,
  getAllDistinctLocalityByCityAndMicromarket
} from "../../feature/slice/MasterAddressSlice";
import {
  isLatitude,
  isLongitude,
  isPincode,
  isAadhar,
  isEmail,
  isMobileNumber,
  isPAN,
} from "../../utils/helpers";

export const amenitiesLists = [
  {
    label: "Printing Facility",
    key: "Printing Facility",
    value: "Printing Facility",
  },
  {
    label: "Passenger Lifts",
    key: "Passenger Lifts",
    value: "Passenger Lifts",
  },
  {
    label: "24/7 Office Access",
    key: "24/7 Office Access",
    value: "24/7 Office Access",
  },
  {
    label: "Cafeteria",
    key: "Cafeteria",
    value: "Cafeteria",
  },
  {
    label: "Server Room",
    key: "Server Room",
    value: "Server Room",
  },
  {
    label: "Window Coverings",
    key: "Window Coverings",
    value: "Window Coverings",
  },
  {
    label: "Support Staff",
    key: "Support Staff",
    value: "Support Staff",
  },
  {
    label: "Managed Reception",
    key: "Managed Reception",
    value: "Managed Reception",
  },
  {
    label: "Prime Locations",
    key: "Prime Locations",
    value: "Prime Locations",
  },
  {
    label: "WIFI",
    key: "WIFI",
    value: "WIFI",
  },
  {
    label: "Drinking Water",
    key: "Drinking Water",
    value: "Drinking Water",
  },
  {
    label: "Cubicle",
    key: "Cubicle",
    value: "Cubicle",
  },
  {
    label: "Conference Room",
    key: "Conference Room",
    value: "Conference Room",
  },
  {
    label: "Beverages",
    key: "Beverages",
    value: "Beverages",
  },
  {
    label: "CCTV Surveillance",
    key: "CCTV Surveillance",
    value: "CCTV Surveillance",
  },
  {
    label: "Car Parking",
    key: "Car Parking",
    value: "Car Parking",
  },
  {
    label: "Bike Parking",
    key: "Bike Parking",
    value: "Bike Parking",
  },
  {
    label: "Meeting Room",
    key: "Meeting Room",
    value: "Meeting Room",
  },
  {
    label: "Air Conditioning",
    key: "Air Conditioning",
    value: "Air Conditioning",
  },
  {
    label: "Gym",
    key: "Gym",
    value: "Gym",
  },
  {
    label: "Parking",
    key: "Parking",
    value: "Parking",
  },
  {
    label: "Swimming pools",
    key: "Swimming pools",
    value: "Swimming pools",
  },
  {
    label: "Spa",
    key: "Spa",
    value: "Spa",
  },
  {
    label: "Billiards Table",
    key: "Billiards Table",
    value: "Billiards Table",
  },
  {
    label: "Kid's Play Area",
    key: "Kid's Play Area",
    value: "Kid's Play Area",
  },
  {
    label: "CCTV",
    key: "CCTV",
    value: "CCTV",
  },
  {
    label: "Clubhouse",
    key: "Clubhouse",
    value: "Clubhouse",
  },
  {
    label: "Social area or rooftop garden",
    key: "Social area or rooftop garden",
    value: "Social area or rooftop garden",
  },
  {
    label: "Balcony",
    key: "Balcony",
    value: "Balcony",
  },
  {
    label: "Smart home",
    key: "Smart home",
    value: "Smart home",
  },
  {
    label: "Security systems and intercom",
    key: "Security systems and intercom",
    value: "Security systems and intercom",
  },
  {
    label: "Bathtubs",
    key: "Bathtubs",
    value: "Bathtubs",
  },
  {
    label: "Community Garden",
    key: "Community Garden",
    value: "Community Garden",
  },
  {
    label: "Games Room",
    key: "Games Room",
    value: "Games Room",
  },
  {
    label: "Snooze Room",
    key: "Snooze Room",
    value: "Snooze Room",
  },
  {
    label: "EV Charger",
    key: "EV Charger",
    value: "EV Charger",
  },
  {
    label: "Pet Friendly",
    key: "Pet Friendly",
    value: "Pet Friendly",
  },
  {
    label: "Green Building/Sustainability",
    key: "Green Building/Sustainability",
    value: "Green Building/Sustainability",
  },
  {
    label: "Building Cafeteria",
    key: "Building Cafeteria",
    value: "Building Cafeteria",
  },
  {
    label: "Valet",
    key: "Valet",
    value: "Valet",
  },
  {
    label: "Retail",
    key: "Retail",
    value: "Retail",
  },
  {
    label: "Cafe",
    key: "Cafe",
    value: "Cafe",
  },
  {
    label: "Natural Lighting",
    key: "Natural Lighting",
    value: "Natural Lighting",
  },
  {
    label: "Garden",
    key: "Garden",
    value: "Garden",
  },
  {
    label: "Playground",
    key: "Playground",
    value: "Playground",
  },
];

export const buildingClassification = [
  {
    label: "Offices",
    key: "Offices",
    value: "Offices",
  },
  {
    label: "IT Park",
    key: "IT Park",
    value: "IT Park",
  },
  {
    label: "IT SEZ",
    key: "IT SEZ",
    value: "IT SEZ",
  },
  {
    label: "Mall",
    key: "Mall",
    value: "Mall",
  },
];

const getAmenitiesListingEle = () => {
  return (
    <>
      {amenitiesLists?.map((item) => (
        <Select.Option value={item?.label} key={item?.key}>
          {item?.label}
        </Select.Option>
      ))}
    </>
  );
};
const getACTypeListingEle = () => {
  let data = [
    {
      label: "Chiller",
      key: "Chiller",
    },
    {
      label: "VRV",
      key: "VRV",
    },
    {
      label: "Split",
      key: "Split",
    },
  ];
  return (
    <>
      {data?.map((item) => (
        <Select.Option value={item?.label} key={item?.key}>
          {item?.label}
        </Select.Option>
      ))}
    </>
  );
};

const getCommonCafeteriaListingEle = () => {
  let data = [
    {
      label: "Yes",
      key: "Yes",
    },
    {
      label: "No",
      key: "No",
    },
  ];
  return (
    <>
      {data?.map((item) => (
        <Select.Option value={item?.label} key={item?.key}>
          {item?.label}
        </Select.Option>
      ))}
    </>
  );
};

const getPowerBackupListingEle = () => {
  let data = [
    {
      label: "100%",
      key: "100%",
    },
    {
      label: "Common Areas",
      key: "Common Areas",
    },
  ];
  return (
    <>
      {data?.map((item) => (
        <Select.Option value={item?.label} key={item?.key}>
          {item?.label}
        </Select.Option>
      ))}
    </>
  );
};

const getBuildingStatusListingEle = () => {
  let data = [
    {
      label: "Ready",
      key: "Ready",
    },
    {
      label: "Under Construction",
      key: "Under Construction",
    },
  ];
  return (
    <>
      {data?.map((item) => (
        <Select.Option value={item?.label} key={item?.key}>
          {item?.label}
        </Select.Option>
      ))}
    </>
  );
};

const getBuildingTypeListingEle = () => {
  let data = [
    {
      label: "Residential",
      key: "Residential",
    },
    {
      label: "Commercial",
      key: "Commercial",
    },
  ];
  return (
    <>
      {data?.map((item) => (
        <Select.Option value={item?.label} key={item?.key}>
          {item?.label}
        </Select.Option>
      ))}
    </>
  );
};

function PropertyDetailsCard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [showCreateTxnBtn, setShowCreateTxnBtn] = useState(false);
  const [manualInputTCA, setManualInputTCA] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isProfilePic, setIsProfilePic] = useState(false);
  const [deletedFileList, setDeletedFileList] = useState([]);
  const {
    isBuildingInfoEditable,
    loading,
    propertyDetails,
    microMarketMaster,
    propertyId,
  } = useSelector((store) => store.propertyDetails);
  const {
    stateMaster,
    cityMaster,
    localityMaster,
  } = useSelector((store) => store.masterAddressDetails);

  useEffect(() => {
    if (id) {
      dispatch(setPropertyId(id));
      dispatch(setBuildingInfoEditable(false));
      dispatch(getPropertyDetailsData(id));
    } else dispatch(setBuildingInfoEditable(true));

    dispatch(getAllDistinctStateMaster());
  }, []);

  useEffect(() => {
    if (!propertyId) return;
    dispatch(setBuildingInfoEditable(false));
    dispatch(getPropertyDetailsData(propertyId));
  }, [propertyId]);

  useEffect(() => {
    let propertyImages = [];
    if (propertyDetails?.propertyImages && propertyDetails?.profileImage) {
      propertyImages = [
        ...propertyDetails?.propertyImages,
        propertyDetails?.profileImage,
      ];
    } else if (
      propertyDetails?.propertyImages &&
      !propertyDetails?.profileImage
    ) {
      propertyImages = [...propertyDetails?.propertyImages];
    } else if (
      !propertyDetails?.propertyImages &&
      propertyDetails?.profileImage
    ) {
      propertyImages = [propertyDetails?.profileImage];
    }

    setFileList(propertyImages);

    if (propertyDetails?.stateId) {
      dispatch(getAllDistinctCityByState(propertyDetails?.stateId));
    }
    if (propertyDetails?.cityId) {
      dispatch(getAllDistinctMicroMarketByCity(propertyDetails?.cityId));
    }
    if (propertyDetails?.microMarketId) {
      dispatch(
        getAllDistinctLocalityByCityAndMicromarket(propertyDetails?.microMarketId)
      );
    }
  }, [
    propertyDetails?.stateId,
    propertyDetails?.cityId,
    propertyDetails?.microMarketId,
    propertyDetails?.propertyImages,
    propertyDetails?.profileImage,
  ]);
  useEffect(() => {
    if (
      propertyDetails?.propertyImages &&
      propertyDetails?.propertyImages?.length > 0
    ) {
      setSelectedImage(
        propertyDetails?.propertyImages?.filter(
          (item) => item.isProfile === true
        )[0]
      );
      const profilePicIndex = propertyDetails?.propertyImages?.findIndex(
        (item) => item.isProfile === true
      );
      if (profilePicIndex !== -1) setIsProfilePic(true);
    }
  }, [propertyDetails?.propertyImages]);
  useEffect(() => {
    if (microMarketMaster.length === 0)
      dispatch(getAllDistinctLocalityByCityAndMicromarket());
  }, [microMarketMaster]);

  const handleImageClick = (file) => {
    if (isBuildingInfoEditable) {
      if (selectedImage === file) {
        setSelectedImage(null); // Deselect the image if it's already selected
        setIsProfilePic(false); // Deselect the profile picture checkbox
      } else {
        setSelectedImage(file); // Select the new image
        setIsProfilePic(false); // Deselect the profile picture checkbox
      }
    }
  };
  const handleDeleteImage = (fileToDelete) => {
    if (isBuildingInfoEditable) {
      // Find the index of the file to delete based on a unique identifier (e.g., id or another unique property)
      const indexToDelete = fileList.findIndex(
        (file) => file.uid === fileToDelete.uid
      );

      const deletedFiles = [...deletedFileList, fileList[indexToDelete]?.url];

      setDeletedFileList(deletedFiles);

      if (indexToDelete !== -1) {
        // Create a new array without the deleted file
        const newFileList = fileList.filter(
          (_, index) => index !== indexToDelete
        );

        // Update state with the new file list
        setFileList(newFileList);
        if (
          (newFileList && newFileList?.length === 0) ||
          selectedImage?.uid === fileToDelete?.uid
        )
          setSelectedImage(null);

        // Dispatch an action to update Redux with the new file list
        dispatch(
          setBuildingsInfo({
            name: "propertyImages",
            value: newFileList,
          })
        );
      }
    }
  };

  const handleProfilePicChange = (e) => {
    setIsProfilePic(e.target.checked);
  };

  const props = {
    onRemove: handleDeleteImage,
    beforeUpload: (file) => {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      const isAllowed = allowedTypes.includes(file.type);
      if (!isAllowed) {
        message.error("You can only upload JPG, JPEG, or PNG files!");
        return false;
      }
      const uuid = uuidv4(); // Generate UUID for the file
      file.uid = uuid; // Assign UUID as unique identifier for Ant Design Upload
      file.name = uuid; // Append UUID to the file name
      setFileList([...fileList, file]);
      return file;
    },
    onChange: (info) => {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      const isAllowed = allowedTypes.includes(info.file.type);
      if (!isAllowed) return;
      const currFile = info.fileList.filter(
        (file) => file.uid === info.file.uid
      );
      dispatch(
        setBuildingsInfo({
          name: "propertyImages",
          value: [...(propertyDetails?.propertyImages || []), currFile[0]],
        })
      );
    },
  };

  const getStateListingEle = () => {
    if (!stateMaster) return [];
    let data = [...stateMaster];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.value} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const getCityListingEle = () => {
    if (!cityMaster) return [];
    let data = [...cityMaster];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.value} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const getMicromarketListingEle = () => {
    if (!microMarketMaster) return [];
    let data = [...microMarketMaster];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.value} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const getLocalityListingEle = () => {
    if (!localityMaster) return null;
    let data = [...localityMaster];

    return (
      <>
        {data.map((item) => (
          <Select.Option value={item?.value} key={item?._id}>
            {item?.label ?? item?.locality}
          </Select.Option>
        ))}
      </>
    );
  };


  const getBuildingClassificationListingEle = () => {
    let data = [];
    if (propertyDetails?.buildingType === "Residential") {
      data = [];
    } else if (propertyDetails?.buildingType === "Commercial") {
      data = buildingClassification;
    }
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const buildingsInfoFormElementsColForUpdate = [
    {
      type: "input",
      label: "Property Name",
      required: true,
      name: "buildingName",
      placeholder: "Property Name",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.buildingName,
    },
    {
      type: "input",
      label: "Total Chargeable Area",
      name: "totalChargeableArea",
      placeholder: "Total Chargeable Area",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.totalChargeableArea,
    },
    {
      type: "input",
      label: "Property Structure",
      name: "buildingStructure",
      placeholder: "Property Structure",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.buildingStructure,
    },
    {
      type: "input",
      label: "Total No. of Transactions",
      name: "numberOfTransactions",
      placeholder: "Total No. of Transactions",
      disabled: true,
      value: propertyDetails?.numberOfTransactions,
    },
    {
      type: "input",
      label: "Total Transaction Amount",
      name: "totalTransactionAmount",
      placeholder: "Total Transaction Amount",
      disabled: true,
      value: propertyDetails?.totalTransactionAmount,
    },
    {
      type: "dropdown",
      mode: "multiple",
      label: "Amenities",
      name: "amenities",
      placeholder: "Select Amenities",
      children: getAmenitiesListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.amenities,
    },
    {
      type: "inputNumber",
      label: "Parking Information of Two Wheeler",
      name: "parkingInformationOfTwoWheeler",
      placeholder: "Two Wheeler",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.parkingInformationOfTwoWheeler,
    },
    {
      type: "inputNumber",
      label: "Parking Information of Four Wheeler",
      name: "parkingInformationOfFourWheeler",
      placeholder: "Four Wheeler Parking Info",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.parkingInformationOfFourWheeler,
    },
    {
      type: "input",
      label: "Developer",
      name: "developer",
      placeholder: "Developer",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.developer,
    },
    {
      type: "inputNumber",
      label: "Efficiency (%)",
      name: "efficiency",
      placeholder: "Efficiency (%)",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.efficiency,
    },
    {
      type: "input",
      label: "Loading (%)",
      name: "loadingPercent",
      placeholder: "Loading (%)",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.loadingPercent,
    },
    {
      type: "input",
      label: "Avg CAM Chargeable",
      name: "avgCAMChargeable",
      placeholder: "Avg CAM Chargeable",
      disabled: true,
      value: propertyDetails?.avgCAMChargeable,
    },
    {
      type: "input",
      label: "Avg CAM Carpet",
      name: "avgCAMCarpet",
      placeholder: "Avg CAM Carpet",
      disabled: true,
      value: propertyDetails?.avgCAMCarpet,
    },
    {
      type: "input",
      label: "Avg FloorPlate Carpet",
      name: "avgFloorPlateCarpet",
      placeholder: "Avg FloorPlate Carpet",
      disabled: true,
      value: propertyDetails?.avgFloorPlateCarpet,
    },
    {
      type: "input",
      label: "Avg FloorPlate Chargeable",
      name: "avgFloorPlateChargeable",
      placeholder: "Avg FloorPlate Chargeable",
      disabled: true,
      value: propertyDetails?.avgFloorPlateChargeable,
    },
    {
      type: "input",
      label: "Avg Rent Rate (INR/ Sqft)",
      name: "avgRentRate",
      placeholder: "Avg Rent Rate (INR/ Sqft)",
      disabled: true,
      value: propertyDetails?.avgRentRate,
    },
    {
      type: "input",
      label: "Avg Sale Rate (INR/Sqft)",
      name: "avgSaleRate",
      placeholder: "Avg Sale Rate (INR/Sqft)",
      disabled: true,
      value: propertyDetails?.avgSaleRate,
    },
    {
      type: "input",
      label: "Average CAM",
      name: "averageCAM",
      placeholder: "Average CAM",
      disabled: true,
      value: propertyDetails?.averageCAM,
    },
    {
      type: "dropdown",
      label: "Common Cafeteria",
      name: "commonCafeteria",
      placeholder: "Common Cafeteria",
      children: getCommonCafeteriaListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.commonCafeteria,
    },
    {
      type: "dropdown",
      label: "Power Backup",
      name: "powerBackup",
      placeholder: "Power Backup",
      children: getPowerBackupListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.powerBackup,
    },
    {
      type: "dropdown",
      label: "AC Type",
      name: "acType",
      placeholder: "Select AC Type",
      children: getACTypeListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.acType,
    },
    {
      type: "input",
      label: "Remarks",
      name: "remarks",
      placeholder: "Remarks",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.remarks,
    },
    {
      type: "dropdown",
      label: "Property Type",
      name: "buildingType",
      required: true,
      placeholder: "Select Property Type",
      children: getBuildingTypeListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.buildingType,
    },
    {
      type: "dropdown",
      label: "Property Classification",
      name: "buildingClassification",
      placeholder: "Select Classification",
      children: getBuildingClassificationListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.buildingClassification,
    },
    {
      type: "dropdown",
      label: "Property Status",
      name: "buildingStatus",
      placeholder: "Select Property Status",
      children: getBuildingStatusListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.buildingStatus,
    },
    {
      type: "input",
      label: "Property Stats",
      name: "buildingStats",
      placeholder: "Property Stats",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.buildingStats,
    },
    {
      type: "inputNumber",
      label: "Property Age",
      name: "buildingAge",
      placeholder: "Property Age",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.buildingAge,
    },
    {
      type: "input",
      label: "Grade of Property",
      name: "gradeOfBuilding",
      placeholder: "Grade of Property",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.gradeOfBuilding,
    },
    {
      type: "inputNumber",
      label: "Total Unit",
      name: "totalUnit",
      placeholder: "Total Unit",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.totalUnit,
    },
    {
      type: "datepicker",
      label: "Proposed Availability Date",
      name: "proposedAvailabilityDate",
      placeholder: "DD-MM-YYYY",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.proposedAvailabilityDate
        ? dayjs(propertyDetails?.proposedAvailabilityDate)
        : null,
      defaultValue: propertyDetails?.proposedAvailabilityDate
        ? dayjs(propertyDetails?.proposedAvailabilityDate)
        : null,
    },
    {
      type: "textArea",
      label: "Property Description",
      name: "propertyDescription",
      placeholder: "Property Description",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.propertyDescription,
      width: "500px",
    },
    {
      type: "input",
      label: "Address",
      name: "address",
      placeholder: "Address",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.address,
    },
    {
      type: "input",
      label: "Street",
      name: "street",
      placeholder: "Street",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.street,
    },
    {
      type: "input",
      label: "Plot No.",
      name: "plotNo",
      placeholder: "Plot No.",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.plotNo,
    },
    {
      type: "input",
      label: "Block No.",
      name: "blockNo",
      placeholder: "Block No.",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.blockNo,
    },
    {
      type: "inputNumber",
      label: "Pincode",
      name: "pincode",
      placeholder: "Pincode",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.pincode,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isPincode(value)) {
              return Promise.reject("Please input correct pincode!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
    {
      type: "dropdown",
      label: "State",
      required: true,
      name: "state",
      placeholder: "State",
      children: getStateListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.state,
    },
    {
      type: "dropdown",
      label: "City",
      required: true,
      name: "city",
      placeholder: "City",
      children: getCityListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.city,
    },
    {
      type: "dropdown",
      label: "Micromarket",
      name: "microMarket",
      placeholder: "Micromarket",
      children: getMicromarketListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.microMarket,
    },
    {
      type: "dropdown",
      label: "Locality",
      required: true,
      name: "locality",
      placeholder: "Locality",
      children: getLocalityListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.locality,
    },
    {
      type: "input",
      label: "Latitude",
      name: "latitude",
      placeholder: "Latitude",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.latitude,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isLatitude(value)) {
              return Promise.reject("Please input correct latitude!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
    {
      type: "input",
      label: "Longitude",
      name: "longitude",
      placeholder: "Longitude",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.longitude,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isLongitude(value)) {
              return Promise.reject("Please input correct longitude!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
  ];

  const representativeFormElementsCol = [
    {
      type: "input",
      label: "Name",
      name: "name",
      placeholder: "Name",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.name,
    },
    {
      type: "input",
      label: "Email",
      name: "email",
      placeholder: "Email",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.email,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isEmail(value)) {
              return Promise.reject("Please input correct Email!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
    {
      type: "inputNumber",
      label: "Contact",
      name: "contact",
      placeholder: "Contact",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.contact,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isMobileNumber(value)) {
              return Promise.reject("Please input correct Contact!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
    {
      type: "input",
      label: "Aadhar No",
      name: "aadharNo",
      placeholder: "Aadhar No",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.aadharNo,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isAadhar(value)) {
              return Promise.reject("Please input correct Aadhar No.!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
    {
      type: "input",
      label: "PAN",
      name: "PAN",
      placeholder: "PAN",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.PAN,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isPAN(value)) {
              return Promise.reject("Please input correct PAN!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
  ];

  const buildingsInfoFormElementsColForSave = [
    {
      type: "input",
      label: "Property Name",
      required: true,
      name: "buildingName",
      placeholder: "Property Name",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.buildingName,
    },
    {
      type: "input",
      label: "Total Chargeable Area",
      name: "totalChargeableArea",
      placeholder: "Total Chargeable Area",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.totalChargeableArea,
    },
    {
      type: "input",
      label: "Property Structure",
      name: "buildingStructure",
      placeholder: "Property Structure",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.buildingStructure,
    },
    {
      type: "dropdown",
      mode: "multiple",
      label: "Amenities",
      name: "amenities",
      placeholder: "Select Amenities",
      children: getAmenitiesListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.amenities,
    },
    {
      type: "inputNumber",
      label: "Parking Information of Two Wheeler",
      name: "parkingInformationOfTwoWheeler",
      placeholder: "Two Wheeler",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.parkingInformationOfTwoWheeler,
    },
    {
      type: "inputNumber",
      label: "Parking Information of Four Wheeler",
      name: "parkingInformationOfFourWheeler",
      placeholder: "Four Wheeler Parking Info",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.parkingInformationOfFourWheeler,
    },
    {
      type: "input",
      label: "Developer",
      name: "developer",
      placeholder: "Developer",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.developer,
    },
    {
      type: "inputNumber",
      label: "Efficiency (%)",
      name: "efficiency",
      placeholder: "Efficiency (%)",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.efficiency,
    },
    {
      type: "input",
      label: "Loading (%)",
      name: "loadingPercent",
      placeholder: "Loading (%)",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.loadingPercent,
    },
    {
      type: "dropdown",
      label: "Common Cafeteria",
      name: "commonCafeteria",
      placeholder: "Common Cafeteria",
      children: getCommonCafeteriaListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.commonCafeteria,
    },
    {
      type: "dropdown",
      label: "Power Backup",
      name: "powerBackup",
      placeholder: "Power Backup",
      children: getPowerBackupListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.powerBackup,
    },
    {
      type: "dropdown",
      label: "AC Type",
      name: "acType",
      placeholder: "Select AC Type",
      children: getACTypeListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.acType,
    },
    {
      type: "input",
      label: "Remarks",
      name: "remarks",
      placeholder: "Remarks",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.remarks,
    },
    {
      type: "dropdown",
      label: "Property Type",
      name: "buildingType",
      required: true,
      placeholder: "Select Property Type",
      children: getBuildingTypeListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.buildingType,
    },
    {
      type: "dropdown",
      label: "Property Classification",
      name: "buildingClassification",
      placeholder: "Select Classification",
      children: getBuildingClassificationListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.buildingClassification,
    },
    {
      type: "dropdown",
      label: "Property Status",
      name: "buildingStatus",
      placeholder: "Select Property Status",
      children: getBuildingStatusListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.buildingStatus,
    },
    {
      type: "input",
      label: "Property Stats",
      name: "buildingStats",
      placeholder: "Property Stats",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.buildingStats,
    },
    {
      type: "inputNumber",
      label: "Property Age",
      name: "buildingAge",
      placeholder: "Property Age",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.buildingAge,
    },
    {
      type: "input",
      label: "Grade of Property",
      name: "gradeOfBuilding",
      placeholder: "Grade of Property",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.gradeOfBuilding,
    },
    {
      type: "inputNumber",
      label: "Total Unit",
      name: "totalUnit",
      placeholder: "Total Unit",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.totalUnit,
    },
    {
      type: "datepicker",
      label: "Proposed Availability Date",
      name: "proposedAvailabilityDate",
      placeholder: "YYYY-MM-DD",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.proposedAvailabilityDate
        ? dayjs(propertyDetails?.proposedAvailabilityDate)
        : null,
      defaultValue: propertyDetails?.proposedAvailabilityDate
        ? dayjs(propertyDetails?.proposedAvailabilityDate)
        : null,
    },
    {
      type: "textArea",
      label: "Property Description",
      name: "propertyDescription",
      placeholder: "Property Description",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.propertyDescription,
      width: "500px",
    },
    {
      type: "input",
      label: "Address",
      name: "address",
      placeholder: "Address",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.address,
    },
    {
      type: "input",
      label: "Street",
      name: "street",
      placeholder: "Street",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.street,
    },
    {
      type: "input",
      label: "Plot No.",
      name: "plotNo",
      placeholder: "Plot No.",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.plotNo,
    },
    {
      type: "input",
      label: "Block No.",
      name: "blockNo",
      placeholder: "Block No.",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.blockNo,
    },
    {
      type: "inputNumber",
      label: "Pincode",
      name: "pincode",
      placeholder: "Pincode",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.pincode,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isPincode(value)) {
              return Promise.reject("Please input correct pincode!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
    {
      type: "dropdown",
      label: "State",
      required: true,
      name: "state",
      placeholder: "State",
      children: getStateListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.state,
    },
    {
      type: "dropdown",
      label: "City",
      required: true,
      name: "city",
      placeholder: "City",
      children: getCityListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.city,
    },
    {
      type: "dropdown",
      label: "Micromarket",
      name: "microMarket",
      placeholder: "Micromarket",
      children: getMicromarketListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.microMarket,
    },
    {
      type: "dropdown",
      label: "Locality",
      required: true,
      name: "locality",
      placeholder: "Locality",
      children: getLocalityListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.locality,
    },
    {
      type: "input",
      label: "Latitude",
      name: "latitude",
      placeholder: "Latitude",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.latitude,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isLatitude(value)) {
              return Promise.reject("Please input correct latitude!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
    {
      type: "input",
      label: "Longitude",
      name: "longitude",
      placeholder: "Longitude",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.longitude,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isLongitude(value)) {
              return Promise.reject("Please input correct longitude!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
  ];

  const getUpdateBuildingPayload = (propertyDetails) => {
    const formData = new FormData();
    if (
      propertyDetails?.propertyImages &&
      propertyDetails?.propertyImages?.length > 0
    ) {
      for (
        let index = 0;
        index < propertyDetails?.propertyImages.length;
        index++
      ) {
        if (propertyDetails?.propertyImages?.[index]?.originFileObj) {
          formData.append(
            "images",
            propertyDetails?.propertyImages?.[index]?.originFileObj
          );
        }
      }
    }

    let filteredDeletedFiles = deletedFileList.filter(Boolean);
    selectedImage &&
      isProfilePic &&
      formData.append("profilePicName", selectedImage?.name);
    deletedFileList &&
      formData.append("deletedFiles", JSON.stringify(filteredDeletedFiles));
    propertyDetails?.buildingName &&
      formData.append("buildingName", propertyDetails?.buildingName);
    propertyDetails?.parkingInformationOfTwoWheeler &&
      formData.append(
        "twoWheelerParking",
        propertyDetails?.parkingInformationOfTwoWheeler
      );
    propertyDetails?.efficiency &&
      formData.append("efficiency", propertyDetails?.efficiency);
    propertyDetails?.powerBackup &&
      formData.append("powerBackup", propertyDetails?.powerBackup);
    propertyDetails?.buildingType &&
      formData.append("buildingType", propertyDetails?.buildingType);
    propertyDetails?.buildingStats &&
      formData.append("buildingStats", propertyDetails?.buildingStats);
    propertyDetails?.propertyDescription &&
      formData.append(
        "propertyDescription",
        propertyDetails?.propertyDescription
      );
    propertyDetails?.localityId &&
      formData.append("locality", propertyDetails?.localityId);
    propertyDetails?.pincodeId &&
      formData.append("pincode", propertyDetails?.pincodeId);
    propertyDetails?.microMarketId &&
      formData.append("micromarket", propertyDetails?.microMarketId);
    propertyDetails?.totalChargeableArea &&
      formData.append(
        "totalChargeableArea",
        propertyDetails?.totalChargeableArea
      );
    formData.append("manualInputTCA", manualInputTCA);
    propertyDetails?.amenities &&
      formData.append("amenities", propertyDetails?.amenities);
    propertyDetails?.parkingInformationOfFourWheeler &&
      formData.append(
        "fourWheelerParking",
        propertyDetails?.parkingInformationOfFourWheeler
      );
    propertyDetails?.loadingPercent &&
      formData.append("loading", propertyDetails?.loadingPercent);
    propertyDetails?.acType &&
      formData.append("acType", propertyDetails?.acType);
    propertyDetails?.buildingClassification &&
      formData.append(
        "buildingClassification",
        propertyDetails?.buildingClassification
      );
    propertyDetails?.buildingAge &&
      formData.append("buildingAge", propertyDetails?.buildingAge);
    propertyDetails?.gradeOfBuilding &&
      formData.append("gradeOfBuilding", propertyDetails?.gradeOfBuilding);
    propertyDetails?.totalUnit &&
      formData.append("totalUnit", propertyDetails?.totalUnit);
    propertyDetails?.address &&
      formData.append("address", propertyDetails?.address);
    propertyDetails?.plotNo &&
      formData.append("plotNo", propertyDetails?.plotNo);
    propertyDetails?.cityId && formData.append("city", propertyDetails?.cityId);
    propertyDetails?.latitude &&
      formData.append("latitude", propertyDetails?.latitude);
    propertyDetails?.buildingStructure &&
      formData.append("buildingStructure", propertyDetails?.buildingStructure);
    propertyDetails?.developer &&
      formData.append("developer", propertyDetails?.developer);
    propertyDetails?.commonCafeteria &&
      formData.append("commonCafeteria", propertyDetails?.commonCafeteria);
    propertyDetails?.remarks &&
      formData.append("remarks", propertyDetails?.remarks);
    propertyDetails?.buildingStatus &&
      formData.append("buildingStatus", propertyDetails?.buildingStatus);
    propertyDetails?.proposedAvailabilityDate &&
      formData.append(
        "proposedAvailabilityDate",
        propertyDetails?.proposedAvailabilityDate
      );
    propertyDetails?.street &&
      formData.append("street", propertyDetails?.street);
    propertyDetails?.blockNo &&
      formData.append("blockNo", propertyDetails?.blockNo);
    propertyDetails?.stateId && formData.append("state", propertyDetails?.stateId);
    propertyDetails?.longitude &&
      formData.append("longitude", propertyDetails?.longitude);
    propertyDetails?.name && formData.append("name", propertyDetails?.name);
    propertyDetails?.email && formData.append("email", propertyDetails?.email);
    propertyDetails?.contact &&
      formData.append("contact", propertyDetails?.contact);
    propertyDetails?.aadharNo &&
      formData.append("aadharNo", propertyDetails?.aadharNo);
    propertyDetails?.PAN && formData.append("PAN", propertyDetails?.PAN);
    if (id) {
      propertyDetails?.numberOfTransactions &&
        formData.append(
          "numberOfTransactions",
          propertyDetails?.numberOfTransactions
        );
      propertyDetails?.avgSaleRate &&
        formData.append("avgSaleRate", propertyDetails?.avgSaleRate);
      propertyDetails?.totalTransactionAmount &&
        formData.append(
          "totalTransactionsAmount",
          propertyDetails?.totalTransactionAmount
        );
      propertyDetails?.averageCAM &&
        formData.append("averageCAM", propertyDetails?.averageCAM);
      propertyDetails?.avgRentRate &&
        formData.append("avgRentRate", propertyDetails?.avgRentRate);
    }
    return formData;
  };

  const savePropertyInfoErrorMessage = (error) => {
    messageApi.open({
      type: "warning",
      content: error || "Something went wrong",
    });
  };

  const savePropertyInfoSuccessMessage = () => {
    messageApi.open({
      type: "success",
      content: "Property created successfully",
    });
  };

  const updatePropertyInfoSuccessMessage = () => {
    messageApi.open({
      type: "success",
      content: "Property updated successfully",
    });
  };

  const propertyInfoValidationMessage = () => {
    messageApi.open({
      type: "warning",
      content: "Please check field validation",
    });
  };

  const handleChangeBuildingsInfo = (element, e, dateVal) => {
    if (element?.name === "totalChargeableArea" && e) {
      setManualInputTCA(true);
    } else if (element?.name === "totalChargeableArea" && !e) {
      setManualInputTCA(false);
    }
    if (element?.type === "datepicker") {
      form.setFieldsValue({
        ...form,
        [element?.name]: dateVal
          ? dayjs(dateVal, "DD-MM-YYYY").add(5, "hour").add(30, "minute")
          : null,
      });
      dispatch(
        setBuildingsInfo({
          name: element?.name,
          value: dateVal ? dayjs(dateVal, "DD-MM-YYYY").add(5, "hour").add(30, "minute") : null,
        })
      );
    } else if (
      element?.type === "inputNumber" ||
      element?.type === "dropdown"
    ) {
      form.setFieldsValue({
        ...form,
        [element?.name]: e,
      });
      dispatch(
        setBuildingsInfo({
          name: element?.name,
          value: e,
        })
      );
      if (element?.name === "state") {
        form.setFieldsValue({
          ...form,
          city: null,
          microMarket: null,
          locality: null,
        });
        dispatch(
          setBuildingsInfo({
            name: "city",
            value: null,
          })
        );
        dispatch(
          setBuildingsInfo({
            name: "microMarket",
            value: null,
          })
        );
        dispatch(
          setBuildingsInfo({
            name: "locality",
            value: null,
          })
        );
      } else if (element?.name === "city") {
        form.setFieldsValue({
          ...form,
          microMarket: null,
          locality: null,
        });
        dispatch(
          setBuildingsInfo({
            name: "microMarket",
            value: null,
          })
        );
        dispatch(
          setBuildingsInfo({
            name: "locality",
            value: null,
          })
        );
      } else if (element?.name === "locality") {
        const localitydata = localityMaster?.filter(
          element => element?.key === e
        );
        form.setFieldsValue({
          ...form,
          locality: e,
        });
        form.setFieldsValue({
          ...form,
          microMarket: localitydata[0].microMarket,
        });
        form.setFieldsValue({
          ...form,
          pincode: localitydata[0].pincode,
        });
        dispatch(
          setBuildingsInfo({
            name: "locality",
            value: e,
          })
        );
        dispatch(
          setBuildingsInfo({
            name: "microMarket",
            value: localitydata[0].microMarket,
          })
        );
        dispatch(
          setBuildingsInfo({
            name: "microMarketId",
            value: localitydata[0].microMarketId,
          })
        );
        dispatch(
          setBuildingsInfo({
            name: "pincode",
            value: localitydata[0].pincode,
          })
        );
        dispatch(
          setBuildingsInfo({
            name: "pincodeId",
            value: localitydata[0].pincodeId,
          })
        );
      }
    } else {
      form.setFieldsValue({
        ...form,
        [element?.name]: e.target.value,
      });
      dispatch(
        setBuildingsInfo({
          name: element?.name,
          value: e.target.value,
        })
      );
    }
    if (element?.name === "state") {
      form.setFieldsValue({
        ...form,
        city: null,
        microMarket: null,
        locality: null,
      });
      dispatch(setBuildingsInfo({ name: "city", value: null }));
      dispatch(setBuildingsInfo({ name: "microMarket", value: null }));
      dispatch(setBuildingsInfo({ name: "locality", value: null }));
    } else if (element?.name === "city") {
      form.setFieldsValue({
        ...form,
        microMarket: null,
        locality: null,
      });
      dispatch(setBuildingsInfo({ name: "microMarket", value: null }));
      dispatch(setBuildingsInfo({ name: "locality", value: null }));
      dispatch(getAllDistinctLocalityByCityAndMicromarket({ city: e }));
    } else if (element?.name === "microMarket") {
      dispatch(getAllDistinctLocalityByCityAndMicromarket({ microMarket: e }));
    }
  };

  const handleChangeRepresentativeInfo = (element, e, dateVal) => {
    if (element?.type === "inputNumber" || element?.type === "dropdown") {
      form.setFieldsValue({
        ...form,
        [element?.name]: e,
      });
      dispatch(
        setBuildingsInfo({
          name: element?.name,
          value: e,
        })
      );
    } else {
      form.setFieldsValue({
        ...form,
        [element?.name]: e.target.value,
      });
      dispatch(
        setBuildingsInfo({
          name: element?.name,
          value: e.target.value,
        })
      );
    }
  };
  const [currentTab, setCurrentTab] = useState("Property Information");

  const handleTabClick = (tabName) => {
    setCurrentTab(tabName);
  };

  return (
    <>
      {loading ? (
        <Spin spinning={loading} size="large" fullscreen />
      ) : (
        <>
          {contextHolder}
          <div>
            <div style={{ padding: "10px", width: "100%" }}>
              <div
                style={{
                  padding: "10px",
                  backgroundColor: "#fff",
                  borderRadius: "10px 10px 0 0",
                  width: "100%",
                }}
              >
                <Form
                  form={form}
                  name="basic"
                  onFinish={async () => {
                    if (isBuildingInfoEditable === true && id) {
                      dispatch(
                        updateBuildingsInfo(
                          getUpdateBuildingPayload(propertyDetails)
                        )
                      );
                      updatePropertyInfoSuccessMessage();
                      setTimeout(() => {
                        dispatch(setLoading(true));
                        navigate("/property");
                        dispatch(setBuildingInfoEditable(false));
                        dispatch(setPropertyId(null));
                        dispatch(setPropertyInitialState());
                      }, 1000);
                    } else if (isBuildingInfoEditable === true && !id) {
                      dispatch(
                        saveBuildingsInfo(getUpdateBuildingPayload(propertyDetails))
                      )
                        .unwrap()
                        .then((data) => {
                          if (data?.status === 201) {
                            savePropertyInfoSuccessMessage();
                            setTimeout(() => {
                              dispatch(setLoading(false));
                              dispatch(setBuildingInfoEditable(false));
                              setShowCreateTxnBtn(true);
                            }, 1000)
                          } else {
                            savePropertyInfoErrorMessage(data?.response?.data?.error);
                          }
                        }).catch((error) => console.log("error", error))
                    }
                  }}
                  onFinishFailed={() => {
                    propertyInfoValidationMessage();
                  }}
                >
                  <div
                    style={{
                      position: "sticky",
                      top: "-16px",
                      zIndex: 1000,
                      backgroundColor: "white",
                      padding: "10px",
                      marginBottom: "20px",
                      width: "100%",
                      display: "inline-flex",
                      flexDirection: "column", // Stacks elements vertically
                    }}
                  >
                    {/* Back Button and Property Title */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                        flexGrow: 1,
                      }}
                    >
                      <Button
                        onClick={() => {
                          navigate("/property"); // Navigates the user back to the previous page in the browser's history
                          dispatch(setBuildingInfoEditable(false));
                          dispatch(setPropertyId(null));
                          dispatch(setPropertyInitialState());
                        }}
                        style={{ border: "none", backgroundColor: "transparent", boxShadow: "none" }}
                      >
                        <FaArrowLeft style={{ marginRight: "8px" }} />
                      </Button>
                      <h1 className="text-2xl font-[500]" style={{ margin: 0 }}>
                        Property
                      </h1>
                    </div>

                    {/* Tabs Section: Property Information, Representative Detail, Building Images */}
                    <div
                      style={{
                        display: "inline-flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingTop: "10px", // Spacing between title and tabs
                        width: "100%",
                        borderBottom: "1px solid #ccc",
                        flexWrap: "wrap", // Ensure wrapping for smaller screens
                        zIndex: 1001, // Ensure it stays above other content
                        backgroundColor: "white", // Retain background during scroll
                      }}
                    >
                      {/* Tab: Property Information */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "10px",
                          boxShadow: "none",
                          borderTopColor: "#fff",
                          borderRightColor: "#fff",
                          borderLeftColor: "#fff",
                          color: currentTab === "Property Information" ? "#fb923c" : "#000",
                          borderBottomColor: currentTab === "Property Information" ? "#fb923c" : "#fff",
                          borderBottomWidth: "3px",
                          borderBottomStyle: "solid",
                        }}
                      >
                        <Checkbox
                          checked={["Property Information", "Representative Detail", "Building Images"].includes(currentTab)}
                          style={{
                            marginRight: "5px",
                            color: currentTab !== "Property Information" ? "#ccc" : "#fb923c",
                          }}
                          onChange={() => handleTabClick("Property Information")}
                        />
                        <Button
                          type="default"
                          onClick={() => handleTabClick("Property Information")}
                          style={{
                            border: "none",
                          }}
                        >
                          Property Information
                        </Button>
                      </div>

                      {/* Tab: Representative Detail */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "10px",
                          boxShadow: "none",
                          borderTopColor: "#fff",
                          borderRightColor: "#fff",
                          borderLeftColor: "#fff",
                          color: currentTab === "Representative Detail" ? "#fb923c" : "#000",
                          borderBottomColor: currentTab === "Representative Detail" ? "#fb923c" : "#fff",
                          borderBottomWidth: "3px",
                          borderBottomStyle: "solid",
                        }}
                      >
                        <Checkbox
                          checked={["Representative Detail", "Building Images"].includes(currentTab)}
                          style={{
                            marginRight: "5px",
                            color: currentTab !== "Representative Detail" ? "#ccc" : "#fb923c",
                          }}
                          onChange={() => handleTabClick("Representative Detail")}
                        />
                        <Button
                          type="default"
                          onClick={() => handleTabClick("Representative Detail")}
                          style={{
                            border: "none",
                          }}
                        >
                          Representative Detail
                        </Button>
                      </div>

                      {/* Tab: Building Images */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "10px",
                          boxShadow: "none",
                          borderTopColor: "#fff",
                          borderRightColor: "#fff",
                          borderLeftColor: "#fff",
                          color: currentTab === "Building Images" ? "#fb923c" : "#000",
                          borderBottomColor: currentTab === "Building Images" ? "#fb923c" : "#fff",
                          borderBottomWidth: "3px",
                          borderBottomStyle: "solid",
                        }}
                      >
                        <Checkbox
                          checked={currentTab === "Building Images"}
                          style={{
                            marginRight: "5px",
                            color: currentTab !== "Building Images" ? "#ccc" : "#fb923c",
                          }}
                          onChange={() => handleTabClick("Building Images")}
                        />
                        <Button
                          type="default"
                          onClick={() => handleTabClick("Building Images")}
                          style={{
                            border: "none",
                          }}
                        >
                          Building Images
                        </Button>
                      </div>
                      {/* Action Buttons: Update, Save, Edit */}
                      <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", marginTop: "10px" }}>
                        {!showCreateTxnBtn && (
                          <>
                            {isBuildingInfoEditable === true && id && (
                              <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                <Button style={{ backgroundColor: "#fb923c", color: "#fff" }} htmlType="submit">
                                  Update Property
                                </Button>
                              </div>
                            )}
                            {isBuildingInfoEditable === true && !id && (
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <Button htmlType="submit">Save Property</Button>
                              </div>
                            )}
                            {isBuildingInfoEditable === false && (
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <Button
                                  style={{ backgroundColor: "rgba(203, 203, 203, 0.3)" }}
                                  onClick={() => dispatch(setBuildingInfoEditable(true))}
                                >
                                  Edit Property
                                </Button>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Tab Content: Property Information */}
                  {currentTab === "Property Information" && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "10px",
                          width: "100%",
                        }}
                      >
                        <Title level={4}>Property Information</Title>
                      </div>
                      <Row gutter={[16, 16]} justify="space-between">
                        <Col
                          xs={24} // Full width on extra small screens
                          sm={12} // Half width on small screens
                          md={8}  // One-third width on medium screens
                          lg={6}  // One-fourth width on large screens
                        >
                          {id ? buildingsInfoFormElementsColForUpdate.map(
                            (element, index) => {
                              if (index % 3 === 0) {
                                return (
                                  <FormElement
                                    key={index}
                                    {...element}
                                    onChange={(e, dateVal) => {
                                      handleChangeBuildingsInfo(
                                        element,
                                        e,
                                        dateVal
                                      );
                                    }}
                                  />
                                );
                              }
                            }
                          )
                            : buildingsInfoFormElementsColForSave.map(
                              (element, index) => {
                                if (index % 3 === 0) {
                                  return (
                                    <FormElement
                                      key={index}
                                      {...element}
                                      onChange={(e, dateVal) => {
                                        handleChangeBuildingsInfo(
                                          element,
                                          e,
                                          dateVal
                                        );
                                      }}
                                    />
                                  );
                                }
                              }
                            )}
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                          {id
                            ? buildingsInfoFormElementsColForUpdate.map(
                              (element, index) => {
                                if (
                                  [
                                    1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34, 37,
                                    40,
                                  ].includes(index)
                                ) {
                                  return (
                                    <FormElement
                                      key={index}
                                      {...element}
                                      onChange={(e, dateVal) => {
                                        handleChangeBuildingsInfo(
                                          element,
                                          e,
                                          dateVal
                                        );
                                      }}
                                    />
                                  );
                                }
                              }
                            )
                            : buildingsInfoFormElementsColForSave.map(
                              (element, index) => {
                                if (
                                  [
                                    1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34, 37,
                                    40,
                                  ].includes(index)
                                ) {
                                  return (
                                    <FormElement
                                      key={index}
                                      {...element}
                                      onChange={(e, dateVal) => {
                                        handleChangeBuildingsInfo(
                                          element,
                                          e,
                                          dateVal
                                        );
                                      }}
                                    />
                                  );
                                }
                              }
                            )}
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                          {id
                            ? buildingsInfoFormElementsColForUpdate.map(
                              (element, index) => {
                                if (
                                  [
                                    2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35, 38,
                                    41,
                                  ].includes(index)
                                ) {
                                  return (
                                    <FormElement
                                      key={index}
                                      {...element}
                                      onChange={(e, dateVal) => {
                                        handleChangeBuildingsInfo(
                                          element,
                                          e,
                                          dateVal
                                        );
                                      }}
                                    />
                                  );
                                }
                              }
                            )
                            : buildingsInfoFormElementsColForSave.map(
                              (element, index) => {
                                if (
                                  [
                                    2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35, 38,
                                    41,
                                  ].includes(index)
                                ) {
                                  return (
                                    <FormElement
                                      key={index}
                                      {...element}
                                      onChange={(e, dateVal) => {
                                        handleChangeBuildingsInfo(
                                          element,
                                          e,
                                          dateVal
                                        );
                                      }}
                                    />
                                  );
                                }
                              }
                            )}
                        </Col>
                      </Row>
                    </>
                  )}

                  {/* Tab Content: Representative Detail */}
                  {currentTab === "Representative Detail" && (
                    <>
                      <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                        <Title level={4}>Property Representative Detail</Title>
                      </div>

                      <Row
                        gutter={[16, 16]} // Add gutter for spacing between columns
                        style={{
                          justifyContent: "space-between",
                          marginRight: "auto",
                          marginLeft: "auto", // Center align on wider screens
                          width: "100%",
                        }}
                      >
                        {/* First Column */}
                        <Col xs={24} sm={12} md={8}>
                          {representativeFormElementsCol.map((element, index) => {
                            if (index % 3 === 0) {
                              return (
                                <FormElement
                                  key={index}
                                  {...element}
                                  onChange={(e, dateVal) => {
                                    handleChangeRepresentativeInfo(element, e, dateVal);
                                  }}
                                />
                              );
                            }
                          })}
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                          {representativeFormElementsCol.map((element, index) => {
                            if ([1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34, 37, 40].includes(index)) {
                              return (
                                <FormElement
                                  key={index}
                                  {...element}
                                  onChange={(e, dateVal) => {
                                    handleChangeRepresentativeInfo(element, e, dateVal);
                                  }}
                                />
                              );
                            }
                          })}
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                          {representativeFormElementsCol.map((element, index) => {
                            if ([2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35, 38, 41].includes(index)) {
                              return (
                                <FormElement
                                  key={index}
                                  {...element}
                                  onChange={(e, dateVal) => {
                                    handleChangeRepresentativeInfo(element, e, dateVal);
                                  }}
                                />
                              );
                            }
                          })}
                        </Col>
                      </Row>
                    </>
                  )}

                  {currentTab === "Building Images" && (
                    <>
                      <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                        <Title level={4}>Add Building Images</Title>
                      </div>
                      <Title level={5}>Note: Extensions allowed - jpg, png, jpeg</Title>
                      <Row
                        gutter={[16, 16]} // Added gutter for spacing between columns
                        style={{ justifyContent: "space-between", margin: "0 auto", width: "100%" }}
                      >
                        {isBuildingInfoEditable && (
                          <Col xs={24} sm={12} md={8}>
                            <Upload
                              {...props}
                              fileList={
                                isBuildingInfoEditable
                                  ? propertyDetails?.propertyImages
                                  : fileList
                              }
                              disabled={!isBuildingInfoEditable}
                              preview={false}
                              maxCount={14}
                              showUploadList={false}
                              multiple={false}
                            >
                              <Button
                                icon={<UploadOutlined />}
                                loading={uploading}
                                disabled={!isBuildingInfoEditable}
                                style={{ width: "100%" }} // Make button full-width on smaller screens
                              >
                                Select File
                              </Button>
                            </Upload>
                          </Col>
                        )}

                        <Col xs={24} sm={12} md={16}>
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {propertyDetails?.propertyImages?.length > 0 ? (
                              propertyDetails?.propertyImages.map((file, index) => (
                                <div
                                  key={index}
                                  style={{
                                    position: "relative",
                                    margin: "10px",
                                    width: "200px", // Set a responsive width for each image
                                  }}
                                >
                                  {isBuildingInfoEditable && (
                                    <>
                                      <Checkbox
                                        style={{
                                          position: "absolute",
                                          top: 3,
                                          left: 5,
                                          zIndex: 1,
                                        }}
                                        onChange={(e) => handleImageClick(file)}
                                        checked={selectedImage === file}
                                        disabled={!isBuildingInfoEditable}
                                      />
                                      <CloseOutlined
                                        style={{
                                          color: "black",
                                          position: "absolute",
                                          top: 3,
                                          right: 5,
                                          zIndex: 1,
                                          cursor: "pointer",
                                        }}
                                        onClick={() => handleDeleteImage(file)}
                                      />
                                    </>
                                  )}
                                  {(file.originFileObj || file.url) && (
                                    <Image
                                      width="100%" // Make the image width 100% to fit its container
                                      height={150}
                                      preview={false}
                                      src={file.url || URL.createObjectURL(file.originFileObj)}
                                      alt={`Uploaded image ${index + 1}`}
                                      style={
                                        file?.isProfile
                                          ? {
                                            border: "4px solid orange",
                                            padding: "2px",
                                            borderRadius: "8px",
                                          }
                                          : { borderRadius: "8px" }
                                      }
                                    />
                                  )}
                                </div>
                              ))
                            ) : (
                              <>
                                {!isBuildingInfoEditable && <h2>No Images to display</h2>}
                              </>
                            )}
                          </div>
                        </Col>
                        {isBuildingInfoEditable && (
                          <Col xs={24} sm={24} md={8}>
                            <div style={{ marginLeft: 0, marginTop: "20px", flex: "1" }}>
                              {selectedImage ? (
                                <>
                                  <Row justify="center">
                                    {(selectedImage.url || selectedImage.originFileObj) && (
                                      <Image
                                        width="100%" // Make the image responsive
                                        height={280}
                                        src={
                                          selectedImage.url ||
                                          (selectedImage.originFileObj &&
                                            URL.createObjectURL(selectedImage.originFileObj))
                                        }
                                        alt="Selected Image Preview"
                                        style={{ borderRadius: "8px" }}
                                      />
                                    )}
                                  </Row>
                                  <Row justify="center">
                                    <Checkbox
                                      style={{ marginTop: 10 }}
                                      onChange={handleProfilePicChange}
                                      checked={isProfilePic}
                                    >
                                      Set this image as your profile picture
                                    </Checkbox>
                                  </Row>
                                </>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: "20px",
                                    border: "1px dashed #d9d9d9",
                                    borderRadius: "8px",
                                    width: "100%", // Responsive container width
                                    height: "280px",
                                  }}
                                >
                                  Your image preview will display here
                                </div>
                              )}
                            </div>
                          </Col>
                        )}
                      </Row>
                    </>
                  )}
                </Form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default PropertyDetailsCard;
