import { client } from "../../utils/apiClient";
import { asyncHandler } from "../../utils/asyncHandler";


const getAllDistinctStateMaster = async () => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-all-distinct-state-master`)
  );
  if (err) return err;
  return data;
};

const getAllDistinctCityByState = async (stateId) => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-all-distinct-city-master?state=${stateId}`)
  );
  if (err) return err;
  return data;
};

const getAllDistinctMicroMarketByCity = async (city) => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-all-distinct-micromarket-master${city ? `?city=${city}` : ''}`)
  );
  
  if (err) return err;
  
  return data;
};


const getAllDistinctLocalityByCityAndMicromarket = async (
  city,
  microMarket
) => {
  if (city && microMarket) {
    const [data, err] = await asyncHandler(
      client.get(`/api/get-all-distinct-locality-master?city=${city}&microMarket=${microMarket}`)
    );
    if (err) return err;
    return data;
  } else if (city && !microMarket) {
    const [data, err] = await asyncHandler(
      client.get(`/api/get-all-distinct-locality-master?city=${city}`)
    );
    if (err) return err;
    return data;
  } else if (!city && microMarket) {
    const [data, err] = await asyncHandler(
      client.get(`/api/get-all-distinct-locality-?microMarket=${microMarket}`)
    );
    if (err) return err;
    return data;
  }
};

export {
  getAllDistinctStateMaster,
  getAllDistinctCityByState,
  getAllDistinctMicroMarketByCity,
  getAllDistinctLocalityByCityAndMicromarket,
};
