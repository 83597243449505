import { client } from "../../utils/apiClient";
import { asyncHandler } from "../../utils/asyncHandler";

const getCompaniesList = async (body) => {
  const [data, err] = await asyncHandler(
    client.get(
      `/api/get-all-companies?pageSize=${body.pageSize}&page=${body.page}`
    )
  );
  if (err) return err;
  return data;
};

const getFilteredCompany = async (body) => {
  const postBody = {
    cin: body?.cin,
    cinFilter: body?.cinFilter,
    completionRatio: +body?.completionRatio,
    completionRatioFilter: body?.completionRatioFilter,
    companyName: body?.companyName,
    companyNameFilter: body?.companyNameFilter,
    companyType: body?.companyType,
    city: body?.city,
    locality: body?.locality,
    startDate: body?.startDate,
    endDate: body?.endDate,
    industryType: body?.industryType,
    industryTypeFilter: body?.industryTypeFilter,
    companyStrength: +body?.companyStrength || null,
    companyStrengthFilter: body?.companyStrengthFilter,
    numberOfDesignatedPartners: +body?.numberOfDesignatedPartners || null,
    numberOfDesignatedPartnersFilter: body?.numberOfDesignatedPartnersFilter
  };
  const [data, err] = await asyncHandler(
    client.post(
      `/api/companies-advanced-search?pageSize=${body?.pageSize}&page=${body.currentPage}`,
      postBody
    )
  );
  if (err) return err;
  return data;
};

const deleteCompany = async (companyId) => {
  const [data, err] = await asyncHandler(client.delete(`/api/delete-company/${companyId}`));
  if (err) return err;
  return data;
}

const getCompanyDetailsData = async (companyId) => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-company-detail/${companyId}`)
  );
  if (err) return err;
  return data;
};

const updateCompanyDetailsData = async (param, companyId) => {
  try {
    const response = await client.patch(
      `/api/update-company-detail/${companyId}`,
      param,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

const saveCompanyDetailsData = async (param) => {
  try {
    const [data, err] = await asyncHandler(client.post(`/api/create-company`, param, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }));
    return data || err;
  } catch (err) {
    console.error(err);
  }
};

const getAllDistinctCountryMaster = async () => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-all-mca-company-distinct-country-master`)
  );
  if (err) return err;
  return data;
};

const getAllDistinctStateByCountry = async (country) => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-all-mca-company-distinct-state-master/${country}`)
  );
  if (err) return err;
  return data;
};

const getAllDistinctCityByState = async (state) => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-all-mca-company-distinct-city-master/${state}`)
  );
  if (err) return err;
  return data;
};

const getAllDistinctDistrictByCity = async (city) => {
  const [data, err] = await asyncHandler(
    client.get(
      `/api/get-all-mca-company-distinct-district-master-by-city/${city}`
    )
  );
  if (err) return err;
  return data;
};

const getAllDistinctLocalityByDistrict = async (district) => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-all-mca-company-distinct-locality-master/${district}`)
  );
  if (err) return err;
  return data;
};

const validateCompanyCIN = async (cin) => {
  const [data, err] = await asyncHandler(
    client.get(`/api/validate-company-cin/${cin}`)
  );
  if (err) return err;
  return data;
};

export {
  getCompaniesList,
  getFilteredCompany,
  getCompanyDetailsData,
  saveCompanyDetailsData,
  updateCompanyDetailsData,
  getAllDistinctCountryMaster,
  getAllDistinctStateByCountry,
  getAllDistinctCityByState,
  getAllDistinctDistrictByCity,
  getAllDistinctLocalityByDistrict,
  validateCompanyCIN,
  deleteCompany,
};
