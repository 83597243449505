import { client } from "../../utils/apiClient";
import { asyncHandler } from "../../utils/asyncHandler";

const getPropertiesList = async (body) => {
  const [data, err] = await asyncHandler(
    client.get(
      `/api/get-all-properties?pageSize=${body.pageSize}&page=${body.page}`
    )
  );
  if (err) return err;
  return data;
};

const bulkUploadProperties = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await client.post("/api/bulk-upload/properties",
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return [response.data, null];
  } catch (error) {
    console.error("Error uploading file:", error.response ? error.response.data : error.message);
    return [null, error];
  }
};

const getFilteredProperty = async (body) => {
  const postBody = {
    propertyName: body?.propertyName,
    propertyNameFilter: body?.propertyNameFilter,
    locality: body?.locality,
    city: body?.city,
    noTransaction: body?.noTransaction,
    noTransactionFilter: body?.noTransactionFilter,
    completionRatio: body?.completionRatio,
    completionRatioFilter: body?.completionRatioFilter,
    startDate: body?.startDate,
    endDate: body?.endDate,
    viewMode: body?.viewMode,
    buildingStructure: body?.buildingStructure,
    buildingStructureFilter: body?.buildingStructureFilter,
    buildingClassification: body?.buildingClassification,
    buildingStatus: body?.buildingStatus,
    totalChargeableArea: body?.totalChargeableArea,
    totalChargeableAreaFilter: body?.totalChargeableAreaFilter,
    proposedAvailabilityStartDate: body?.proposedAvailabilityStartDate,
    proposedAvailabilityEndDate: body?.proposedAvailabilityEndDate,
    gradeOfBuilding: body?.gradeOfBuilding,
    gradeOfBuildingFilter: body?.gradeOfBuildingFilter,
    developer: body?.developer,
    developerFilter: body?.developerFilter,
    totalTransactionsAmount: body?.totalTransactionsAmount,
    totalTransactionsAmountFilter: body?.totalTransactionsAmountFilter,
    lastUpdatedOnStartDate: body?.lastUpdatedOnStartDate,
    lastUpdatedOnEndDate: body?.lastUpdatedOnEndDate,
    updatedBy: body?.updatedBy,
    updatedByFilter: body?.updatedByFilter,
    createdBy: body?.createdBy,
    createdByFilter: body?.createdByFilter,
    buildingStats: body?.buildingStats,
    buildingStatsFilter: body?.buildingStatsFilter,
    amenities: body?.amenities,
    repPersonName: body?.repPersonName,
    repPersonNameFilter: body?.repPersonNameFilter,
    repPersonContact: body?.repPersonContact,
    repPersonContactFilter: body?.repPersonContactFilter,
    repPersonEmail: body?.repPersonEmail,
    repPersonEmailFilter: body?.repPersonEmailFilter
  };
  const [data, err] = await asyncHandler(
    client.post(
      `/api/advanced-search?pageSize=${body?.pageSize}&page=${body.currentPage}`,
      postBody
    )
  );
  if (err) return err;
  return data;
};

const getPropertyDetailsData = async (propertyId) => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-property-detail/${propertyId}`)
  );
  if (err) return err;
  return data;
};

const updatePropertyDetailsData = async (param, propertyId) => {
  try {
    const response = await client.patch(
      `/api/update-property-detail/${propertyId}`,
      param,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

const savePropertyDetailsData = async (param) => {
  try {
    const [data, err] = await asyncHandler(client.post(`/api/create-property`, param, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }));
    if(err) return err;
    return data;
  } catch (err) {
    console.error(err);
  }
};

const getAllDistinctStateMaster = async () => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-all-distinct-state-master`)
  );
  if (err) return err;
  return data;
};

const getAllDistinctCityByState = async (stateId) => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-all-distinct-city-master?state=${stateId}`)
  );
  if (err) return err;
  return data;
};

const getAllDistinctMicroMarketByCity = async (city) => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-all-distinct-micromarket-master${city ? `?city=${city}` : ''}`)
  );
  
  if (err) return err;
  
  return data;
};


const getAllDistinctLocalityByCityAndMicromarket = async (
  city,
  microMarket
) => {
  if (city && microMarket) {
    const [data, err] = await asyncHandler(
      client.get(`/api/get-all-distinct-locality-master?city=${city}&?microMarket=${microMarket}`)
    );
    if (err) return err;
    return data;
  } else if (city && !microMarket) {
    const [data, err] = await asyncHandler(
      client.get(`/api/get-all-distinct-locality-master?city=${city}`)
    );
    if (err) return err;
    return data;
  } else if (!city && microMarket) {
    const [data, err] = await asyncHandler(
      client.get(`/api/get-all-distinct-locality-?microMarket=${microMarket}`)
    );
    if (err) return err;
    return data;
  }
};

const deleteProperty = async (propertyId) => {
  const [data, err] = await asyncHandler(client.delete(`/api/delete-property/${propertyId}`));
  if (err) return err;
  return data;
};

export {
  getPropertiesList,
  getFilteredProperty,
  getPropertyDetailsData,
  updatePropertyDetailsData,
  savePropertyDetailsData,
  getAllDistinctStateMaster,
  getAllDistinctCityByState,
  getAllDistinctMicroMarketByCity,
  getAllDistinctLocalityByCityAndMicromarket,
  deleteProperty,
  bulkUploadProperties,
};
